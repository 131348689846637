$(window).on('load', function(){
	$('#loader').fadeOut();
});

$('#product-single li').click(function(){
	$(this).siblings().removeClass('active');
	$(this).addClass('active');
});

$('#product-slider .slider').slick({
	centerMode: true,
	slidesToScroll: 1,
	slidesToShow: 4,
	autoplay: true,
	autoplaySpeed: 1800,
	cssEase: 'ease-out',
	arrows: false,
	pauseOnHover: true,
	pauseOnFocus: false,
	responsive: [
		{
			breakpoint: 1440,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 800,
			settings: {
				slidesToShow: 2
			}
		},
		{
			breakpoint: 640,
			settings: {
				slidesToShow: 2,
				centerMode: false,
			}
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 1,
			}
		}
	]
});

$('#main-jumbotron .slider').slick({
	arrows: false,
	fade: true,
});

/* -----------------
	CHAT
------------------ */
	$('#product-single .btn').click(function(e){
		e.preventDefault();
		console.log('click');
		
			var info = $(this).parents('.info');
			var prod = info.find('h2').text().trim();
			var size = info.find('.row.active span').text().trim();
			var price = info.find('.row.active strong').text().trim();
			console.log(prod + ' - ' + size + ' ' + price);
			jivo_api.setCustomData([
			    {
			        "title": "Producto",
			        "content": prod + ' - ' + size + ' ' + price,
			    }
			]);
			jivo_api.open();
	});


/* -----------------
	HAMBURGER
------------------ */

	$('.hamburger').click(function(){
		$(this).toggleClass('is-active');
	});

/* -----------------
	  HEADROOM
------------------ */

	var myHeader = document.getElementById('main-header');
	var headroom  = new Headroom(myHeader, {
		offset: 115
	});
	headroom.init();

$('.paroller').paroller();

$('#product-prev').click(function(){
	$('#product-slider .slider').slick('slickPrev');
});

$('#product-next').click(function(){
	$('#product-slider .slider').slick('slickNext');
});

$('#product-slider .slider .product').click(function(){
	var index = $(this).data('slick-index');
	changeSlide(index);
	$('html,body').animate({
		scrollTop: $('#intro').offset().top
	});
});

$('#product-select').change(function(){
	var index = $(this).val();
	changeSlide(index);
	$('html,body').animate({
		scrollTop: $('#product-single').offset().top
	});

});

function changeSlide(index){
	$('#product-single > .row').removeClass('active');
	$('#product-single > .row:eq('+ index + ')')
		.addClass('active')
	    .hide()
	    .fadeIn(); // fix: fadeIn on flex element
	$('#main-header').addClass('headroom--unpinned');
}

var map_full = {
	elemId: 'map',
	zoom: 13,
	mapTypeId: 'roadmap',
	center: {
		lat: 20.5832079,
		lng: -100.4052369
	},
	locations: [
	    ['<strong>El Retablo<br></strong>San Agustín del Retablo 55, El Retablo.<br>CP 76160. Santiago de Querétaro, Qro. <br>Tel: 212 5638', 20.5971033, -100.4036068],
	    ['<strong>El Pueblito<br></strong>Plaza Don Bosco, Local 8.<br>Av. Don Bosco 55, El Pueblito, Qro.<br>Tel: 2254037', 20.5499937, -100.4295907],
	]
}

initializeMap(map_full);

function initializeMap(map){
	console.log('map');
	var center = map.center;
	var locations = map.locations;
	var zoom = map.zoom;

	map = new google.maps.Map(document.getElementById(map.elemId), {
    	center: center,
    	zoom: zoom,
    	disableDefaultUI: true,
    	styles: [{"featureType": "administrative","elementType": "labels.text.fill","stylers": [{"color": "#444444"}]},{"featureType": "landscape","elementType": "all","stylers": [{"color": "#f2f2f2"}]},{"featureType": "landscape.man_made","elementType": "geometry.fill","stylers": [{"visibility": "on"},{"color": "#eae9ed"}]},{"featureType": "landscape.natural","elementType": "geometry.fill","stylers": [{"visibility": "on"},{"color": "#d2e0b7"}]},{"featureType": "landscape.natural.landcover","elementType": "geometry.fill","stylers": [{"visibility": "on"},{"color": "#d2e0b7"}]},{"featureType": "landscape.natural.terrain","elementType": "geometry","stylers": [{"visibility": "on"},{"color": "#d2e0b7"}]},{"featureType": "poi","elementType": "all","stylers": [{"visibility": "off"}]},{"featureType": "poi.park","elementType": "geometry.fill","stylers": [{"visibility": "on"}]},{"featureType": "road","elementType": "all","stylers": [{"saturation": -100},{"lightness": 45}]},{"featureType": "road","elementType": "geometry.fill","stylers": [{"visibility": "on"},{"color": "#ffffff"}]},{"featureType": "road","elementType": "geometry.stroke","stylers": [{"visibility": "off"}]},{"featureType": "road","elementType": "labels.text.stroke","stylers": [{"visibility": "off"}]},{"featureType": "road.highway","elementType": "all","stylers": [{"visibility": "simplified"}]},{"featureType": "road.arterial","elementType": "labels.icon","stylers": [{"visibility": "off"}]},{"featureType": "transit","elementType": "all","stylers": [{"visibility": "off"}]},{"featureType": "water","elementType": "all","stylers": [{"color": "#b3dced"},{"visibility": "on"}]},{"featureType": "water","elementType": "labels.text.fill","stylers": [{"visibility": "on"},{"color": "#ffffff"}]},{"featureType": "water","elementType": "labels.text.stroke","stylers": [{"visibility": "off"},{"color": "#e6e6e6"}]}]
  	});

	var infowindow =  new google.maps.InfoWindow({});

	var marker, count;

	for (count = 0; count < locations.length; count++) {
	    marker = new google.maps.Marker({
	    	position: new google.maps.LatLng(locations[count][1], locations[count][2]),
	    	map: map,
	    	title: locations[count][0],
	    	icon: {
	    		url: '/assets/img/map_marker.png',
	    		scaledSize: new google.maps.Size(37, 51), // scaled size
			    origin: new google.maps.Point(0, 0), // origin
			    anchor: new google.maps.Point(0, 65) // anchor
			}
	    });

		google.maps.event.addListener(marker, 'click', (function (marker, count) {
    		return function () {
		        infowindow.setContent(locations[count][0]);
		        infowindow.open(map, marker);
		    }
    	})(marker, count));
  	}
}

/* ----------------------- */
// 6. Contact form
/* ----------------------- */

	function revealMessage(msg,form){
		form.find('.form-message').html(msg).addClass('open');
        setTimeout(function() {
        	form.find('.form-message').html('').removeClass('open');
        }, 2000);
	}

	$('.contact-form').submit(function(e) {
		// Prevent the form from actually submitting
		e.preventDefault();
		var form = $(this);

		// Get the post data
		var data = $(this).serialize();

		// block multiple clicks
		form.find('button').attr('disabled',true);

		// Send it to the server
		$.post('/', data, function(response) {
			console.log(response);
	        if (response.success) {
	        	ga('send', {'hitType': 'pageview', 'page': '/formulario-enviado', 'title': 'Formulario enviado'});
	            revealMessage('Gracias por contactarnos, te contactaremos cuanto antes.',form);
	        } else {
	            revealMessage('Hubo un error, intenta de nuevo más tarde.',form);
	        }
	        form.find('button').attr('disabled',false);
	    });
	});